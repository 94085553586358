@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.main-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 8px;

    &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 33%;
        height: 100%;
        background-image: linear-gradient(90deg, rgba(48,44,64,1) 0%, rgba(48,44,64,0.85) 25%, rgba(48,44,64,0) 100%);
        z-index: 10;
    }

    &:before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        width: 33%;
        height: 100%;
        background-image: linear-gradient(90deg, rgba(48,44,64,0) 0%, rgba(48,44,64,0.85) 75%, rgba(48,44,64,1) 100%);
        z-index: 10;
    }

    @media all and (max-width: 780px) {
        &:before,
        &:after {
            content: none;
        }
    }

    .container {
        position: relative;
        z-index: 15;
        max-width: 900px;
    }

    h1 {
        // font-family: "JollyLodger", cursive;
        font-size: 54px;
        font-weight: 700;
        color: #b7ffcf;
        text-align: center;
        // max-width: 285px;
        margin: 0 auto 48px;
    }

    p {
        text-align: center;
        margin: 0 auto 40px;
    }

    .btn-upload-foto {
        font-family: "AlegreyaSans", sans-serif;
        font-size: 30px;
        font-weight: 700;
        color: #302c40;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        background-color: #b7ffcf;
        width: 270px;
        height: 80px;
        border-radius: 40px;
        margin: 0 auto;
    }

    .btn-close {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #605d6c;
        border: none;
        position: fixed;
        top: 15px;
        right: 15px;
        margin: 0;
    
        svg {
            width: 10px;
        }
    }
}

.bg-top {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: -4;
    pointer-events: none
}

.bg-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: -4;
    pointer-events: none
}

.header {
    position: relative;
    z-index: 16;
    // margin-top: 60px;

    @media all and (max-width: 991px) {
        // margin-top: 24px;
    }

    .container {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        max-width: 1680px;
    }

    .btn-app {
        font-size: 34px;
        font-weight: 700;
        font-family: "AlegreyaSans", sans-serif;
        color: #302c40;
        text-transform: uppercase;
        width: 282px;
        height: 90px;
        border-radius: 0;
        background-color: transparent;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 170px;
            display: block;
            background-image: url("/assets/images/bg-app.png");
            background-size: contain;
            background-repeat: no-repeat;
            z-index: -1;
        }

        &.open {
            background-color: #f96a03;
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-input-container {
        position: relative;
    }

    input {
        color: #fff;
        height: 32px;
        width: 100%;
        border-radius: 16px;
        background: none;
        border: 1px solid #fff;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        padding:  0 25px 0 20px;
    }

    .form-area-error {
        border-color: #ff7675;
    }

    .error-message {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        color: #ff7675;
        text-align: left;
        position: absolute;
        bottom: -24px;
        left: 8px;
        margin: 0;

        svg {
            position: relative;
            top: 1px;
            width: 9px;
            margin-right: 3px;
        }
    }

    .form-btn-cancel {
        display: flex;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
        box-sizing: border-box;
        background: none;
        padding: 4px;

        svg {
            width: 100%;
        }
    }

    .btns-container {
        margin-top: 30px;
    }
}

.form-container {
    position: relative;
    // flex-grow: 1;

    &.container {
        max-width: 384px;
        z-index: 14;
    }
}

.slider {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .slick-slide > div {
        padding: 10px 0 40px;
        // margin: 0 24px;
    }

    .slick-list {
        // margin: 0 -24px;
    }

    .slide {
        // width: 72%;
        // height: 100%;
        transform: scale(0.72);
        border-radius: 45px;
        background-color: #615e70;
        position: relative;
        transition: 0.6s;
        filter: blur(8px);
        // padding-top: 30%;
        will-change: transform;
        padding-top: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.6s;
            border-radius: 45px;
        }
    }

    .slick-current {
        transform: scale(1);
        will-change: transform;
        .slide {
            filter: none;
            transform: rotate(-2deg);

            img {
                transform: rotate(7deg);
            }

            .slide-toolptip {
                display: flex;
            }
        }
    }

    .slick-dots {
        bottom: 4px;

        @media all and (max-width: 450px) {
            display: none;
        }

        li { 
            width: 10px;
            height: 10px;
            border-radius: 6px;
            background-color: #423d58;
            opacity: 1;
            margin: 0 4px;

            button {
                width: 100%;
                height: 100%;
                padding: 0;

                &:before {
                    content: none;
                }
            }
        }

        .slick-active {
            width: 36px;
            background-color: #beb4e8;
        }
    }
}

.slick-slide {
    height: auto; 
}

.slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
}

.slide-toolptip {
    display: none;
    position: absolute;
    bottom: 65px;
    left: -160px;
    transform: rotate(7deg);

    p {
        font-size: 34px;
        color: #302c40;
        line-height: 21px;
        background-color: #B7FFCF;
        border-radius: 2px;
        box-shadow: 4px 4px 0 0 rgba(48, 44, 64, 0.72);
        padding: 20px;
        margin: 0;
    }

    .second-text {
        padding-top: 0;
        margin-top: -3px;
    }

    svg {
        width: 150px;
        transform: rotate(-7deg);
        position: absolute;
        bottom: -60px;
        left: 64%;
    }
}

.slide-toolptip-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.suggestion-container {
    display: flex;
    flex-direction: column;
    // width: 274px;
    margin-top: 28px;

    span {
        line-height: 21px;
        text-align: center;
        color: #fff;
        background-color: rgba(255, 255, 255, 0.23);
        border-radius: 12px;
        padding: 4px 36px;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.notice {
    display: none;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    right: 100%;
    margin-bottom: 24px;

    @media all and (max-width: 991px) {
        position: static;
    }

    p {
        font-weight: 500;
        // font-style: italic;
        color: #b7ffcf;
        border: 1px solid #b7ffcf;
        width: 170px;
        border-radius: 16px;
        margin: 0;
        margin-right: 8px;
        padding: 8px;
    }

    svg {
        width: 30px;
    }
}

.term-suggestions-open {
    justify-content: center;
    padding-top: 60px;

    @media all and (max-width: 991px) {
        justify-content: start;
        padding-top: 60px;
    }

    .header {
        margin: 0;
    }

    .slider {
        display: flex;
        // align-items: center;

        @media all and (max-width: 991px) {
            justify-content: flex-start;
        }
    }

    &.main-page h1,
    .slick-slider {
        display: none;
    }

    .notice {
        display: flex;

        p {
            display: inline-block;
        }
    }

    &.main-page .error-message {
        display: block;
    }
}

// loader
.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #302c40;

    .loader-tip {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        color: #01e777;
        margin-bottom: 16px;
    }

    .upload {
        display: none;
    }
}

.loader.loader-with-upload-form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .upload {
        display: block;

        .container {
            max-width: 524px;
        }

        p {
            font-size: 16px;
            color: #a5a3ad;
            line-height: 24px;
            text-align: center;
            margin-bottom: 12px;
        }

        .btns-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        button {
            font-size: 14px;
            font-weight: 700;
            color: #302c40;
            max-width: 270px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            border-radius: 25px;
            text-transform: uppercase;
            border: 1px solid #b7ffcf;
            background-color: #b7ffcf;
            box-sizing: border-box;
            margin: 0 auto;

            svg {
                width: 16px;
                margin-top: -2px;
                margin-left: 10px;
            }
        }

        .btn-grey {
            font-weight: 400;
            color: #aaa;
            border: none;
            background-color: rgba(0, 0, 0, 0);
            margin-top: 16px;
        }
    }

    .main {
        display: none;
    }
}

.spinner-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.avatar-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    pointer-events: none;
}

.loader-text-container {
    position: absolute;
    top: calc(40% + 170px);
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 80%;

    p {
        text-align: center;
    }
}

.triple-spinner {
    display: block;
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: solid 1px rgba(212, 255, 226, 0.2);
}

.triple-spinner2 {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: solid 1px rgba(212, 255, 226, 0.2);
}

.triple-spinner::before,
.triple-spinner2::before  {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 2px solid #b7ffcf;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
}

.triple-spinner::before {
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
    animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner2::before {
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

.dot-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dot-center:before,
.dot-center:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #b7ffcf;
}

.dot-center:before {
    top: -104px;
    transform-origin: 0 104px;
    animation: dotRevolution 3s linear infinite;
}

.dot-center:after {
    top: -124px;
    transform-origin: 0 124px;
    animation: dotRevolutionReverse 1.5s linear infinite;
}

@keyframes dotRevolution {
    0% {
      transform: rotateZ(45deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotateZ(405deg) translate3d(0, 0, 0);
    }
}

@keyframes dotRevolutionReverse {
    0% {
      transform: rotateZ(45deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotateZ(-315deg) translate3d(0, 0, 0);
    }
}

@keyframes dotWindmill {
    0% {
      transform: rotateZ(0deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotateZ(720deg) translate3d(0, 0, 0);
    }
}

// result
.result-page {
    height: var(--window-inner-height);
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    box-sizing: border-box;
    overflow-x: hidden;
    max-width: 600px;
    padding-top: 64px;
    padding-bottom: 24px;
    margin: 0 auto;

    @media all and (max-width: 991px) {
        padding-top: 32px;
    }

    .btns-container {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
        gap: 8px;
        padding-left: 24px;
        padding-right: 24px;

        button {
            font-size: 14px;
            font-weight: 700;
            flex: 1 0;
            color: #302c40;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            border-radius: 25px;
            text-transform: uppercase;
            border: 1px solid #b7ffcf;
            background-color: #b7ffcf;
            box-sizing: border-box;
        }

        .btn-empty {
            color: #b7ffcf;
            background-color: transparent;
        }
    }
}

.result-title {
    text-align: center;
    line-height: 24px;
    margin-bottom: 14px;
}

.creative-block {
    display: flex;
    flex-grow: 1;
    height: 100%;
    // max-height: 64vh;
    position: relative;
    background-color: #605d6c;
    margin-bottom: 24px;
}

.creative-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 694px;
    flex-grow: 1;
    // background-color: rgba(255, 255, 255, 0.23);
    background-color: transparent;
    box-sizing: border-box;
}

.creative {
    height: 100%;
    width: 100%;

    img {
        border-radius: 2px;
        height: 100%;
        width: 100%;
        display: block;
        object-fit: contain;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        // position: absolute;
        z-index: 5;
        margin: 0 auto;
    }
}

// footer
.footer {
    flex-grow: 2;
    display: flex;
    align-items: flex-end;
    margin-top: 44px;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 30px;
    }

    a {
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.6);
        text-decoration: underline;
    }
}

// error
.error-page {
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    padding: 24px 0;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }

    button {
        font-size: 14px;
        font-weight: 700;
        color: #302c40;
        max-width: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 25px;
        text-transform: uppercase;
        border: 1px solid #b7ffcf;
        background-color: #b7ffcf;
        box-sizing: border-box;
    }

    .btn-upload-foto {
        color: #fff;
        width: auto;
        max-width: inherit;
        overflow: hidden;
        background-color: transparent;
        border: 1px solid #b7ffcf;
        padding: 0 12px;

        svg {
            display: none;
        }

        .effects {
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            background-color: #b7ffcf;
            color: #302c40;
            border-radius: 100px;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            align-items: center;

            span {
                padding-left: 12px;
            }
        }
    }

    .btn-upload-foto-full {
        color: #302c40;
        background-color: #b7ffcf;
        border: 1px solid #b7ffcf;
        margin-top: 16px;
    }
}

.error-content {
    svg {
        max-width: 450px;
        margin-bottom: 16px;
    }

    p {
        text-align: center;
        max-width: 540px;
        margin-bottom: 24px;
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.82);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-container {
    background-color: #302c40;
    width: 90%;
    max-width: 524px;
    border-radius: 26px;
    box-sizing: border-box;
    position: relative;
    padding: 45px;
}

.modal {
    h3 {
        // font-family: "JollyLodger", cursive;
        font-size: 28px;
        text-align: center;
        line-height: 30px;
        margin-bottom: 16px;
    }

    p {
        line-height: 20px;
        text-align: center;
        margin-bottom: 24px;
    }

    button {
        font-size: 14px;
        font-weight: 700;
        color: #302c40;
        max-width: 270px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 25px;
        text-transform: uppercase;
        border: 1px solid #b7ffcf;
        background-color: #b7ffcf;
        box-sizing: border-box;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }
    }

    .tag {
        font-weight: 700;
        color: #b7ffcf;
    }

    .btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            svg {
                width: 16px;
                margin-left: 10px;
            }
        }
    }

    .btn-grey {
        font-weight: 400;
        color: #aaaaaa;
        border: none;
        background-color: transparent;
    }

    .btn-close {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #605d6c;
        border: none;
        position: absolute;
        top: -40px;
        right: 0;
        margin: 0;
    
        svg {
            width: 10px;
        }
    }
}

.modal-image {
    display: flex;
    max-height: 24vh;
    margin: 0 auto 24px;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        max-width: 524px;
    }

    p {
        font-size: 16px;
        color: #a5a3ad;
        line-height: 24px;
        text-align: center;
        margin-bottom: 12px;
    }

    button {
        font-size: 14px;
        font-weight: 700;
        color: #302c40;
        max-width: 270px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 25px;
        text-transform: uppercase;
        border: 1px solid #b7ffcf;
        background-color: #b7ffcf;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            svg {
                width: 16px;
                margin-left: 10px;
            }
        }
    }

    .btn-grey {
        font-weight: 400;
        color: #aaaaaa;
        border: none;
        background-color: transparent;
        margin-top: 16px;
    }
}

.loader-bg {
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.loader-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-bg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    pointer-events: none;
}

.loader-bg:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: rgba(0,0,0,.3);
    z-index: 10;
}

.loader-roller-wrapper {
    width: 80px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 20;
    margin: 0 auto;
}

.loader-roller {
    width: 80px;
    height: 50px;
    top: 0;
    left: 0;
    position: absolute;

    &:after {
        content: "";
        top: auto;
        position: absolute;
        display: block;
        animation: shadow 1.2s linear infinite;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 12px;
        border-radius: 50%;
        background-color: #000;
    }

    .roller {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 1.2s;
    
        &:first-child {
            left: 0;
            animation-name: rollercoaster;
            transform: rotate(135deg);
        }

        &:last-child {
            right: 0;
            animation-name: rollercoaster2;
            transform: rotate(-45deg);
        }

        &:before {
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            background-color: #a4e6a7;
            border-radius: 50%;
        }
    }
}

.loader-roller2 .roller,
.loader-roller2:after {
    animation-delay: .15s
}

.loader-roller3 .roller,
.loader-roller3:after {
    animation-delay: .3s
}

@keyframes rollercoaster {
    0% {
        transform: rotate(135deg)
    }

    8% {
        transform: rotate(240deg)
    }

    20% {
        transform: rotate(300deg)
    }

    40% {
        transform: rotate(380deg)
    }

    45% {
        transform: rotate(440deg)
    }

    50% {
        transform: rotate(495deg);
        opacity: 1
    }

    50.1% {
        transform: rotate(495deg);
        opacity: 0
    }

    to {
        transform: rotate(495deg);
        opacity: 0
    }
}

@keyframes rollercoaster2 {
    0% {
        opacity: 0
    }

    49.9% {
        opacity: 0
    }

    50% {
        opacity: 1;
        transform: rotate(-45deg)
    }

    58% {
        transform: rotate(-160deg)
    }

    70% {
        transform: rotate(-240deg)
    }

    80% {
        transform: rotate(-300deg)
    }

    90% {
        transform: rotate(-340deg)
    }

    to {
        transform: rotate(-405deg)
    }
}

@keyframes shadow {
    0% {
        opacity: .3;
        -webkit-transform: translateX(35px) scale(.25);
        transform: translateX(35px) scale(.25)
    }

    8% {
        -webkit-transform: translateX(9px) scale(1);
        transform: translateX(9px) scale(1)
    }

    20% {
        -webkit-transform: translateX(0) scale(.6);
        transform: translateX(0) scale(.6)
    }

    40% {
        -webkit-transform: translateX(-5px) scale(.25);
        transform: translateX(-5px) scale(.25);
        opacity: .1
    }

    50% {
        -webkit-transform: translateX(32px) scale(.6);
        transform: translateX(32px) scale(.6);
        opacity: .3
    }

    60% {
        -webkit-transform: translateX(70px) scale(1);
        transform: translateX(70px) scale(1);
        opacity: .05
    }

    65% {
        -webkit-transform: translateX(78px) scale(.6);
        transform: translateX(78px) scale(.6)
    }

    80% {
        -webkit-transform: translateX(65px) scale(.25);
        transform: translateX(65px) scale(.25);
        opacity: .1
    }

    90% {
        -webkit-transform: translateX(43px) scale(.4);
        transform: translateX(43px) scale(.4)
    }

    to {
        -webkit-transform: translateX(32px);
        transform: translateX(32px);
        opacity: .3
    }
}

.tab-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex-shrink: 0;
    
    width: 100%;
    max-width: 730px;
    box-sizing: border-box;
    padding-left: 2px;
    padding-right: 2px;
    // padding-bottom: 10px;
    margin: 0 auto;
}

.tabs-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 0;
    max-width: 688px;
    overflow: auto;
    padding-bottom: 10px;
}

.btn-choice-tab {
    width: 56px;
    height: 56px;
    border-radius: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-shrink: 0;
    font-size: 8px;
    text-align: center;
    color: #fff;
    background-color: rgb(96, 93, 108);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 6px 4px;
    margin-left: 8px;

    .error-icon {
        width: 22px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.btn-choice-tab.active {
    border: 4px solid #b7ffcf;
}

.btn-choice-tab:first-child {
    margin-left: 6px;
}

.btn-choice-tab:last-child {
    margin-right: 6px;
}

// .btn-choice-tab--result_story {
//     background-position: 0 72%;
// }

// .btn-choice-tab--result_square {
//     background-position: center;
// }

// .btn-choice-tab--result_bodies {
//     background-position: 0 60%;  
// }

// .btn-choice-tab--result_without_file_story {
//     background-position: 0 50%;
// }

.recent-container {
    display: flex;
    width: 90%;
    max-width: 524px;
}

.recent {
    background-color: #605d6c;
    box-sizing: border-box;
    border-radius: 6px;
    align-self: flex-start;
    position: relative;
    min-width: 175px;
    padding: 8px 0 6px 0;
    margin-top: 18px;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 42px;
        top: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 9px 6px;
        border-color: transparent transparent #605d6c transparent;
    }

    h2 {
        font-size: 14px;
        line-height: 24px;
        padding-left: 16px;
        margin-bottom: 4px;
    }
}

.recent-images {
    display: flex;
    overflow: auto;
    padding-bottom: 10px;
}

.recent-image {
    position: relative;
    width: 68px;
    height: 68px;
    border-radius: 6px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 6px;

    &:first-child {
        margin-left: 16px;
    }

    &:last-child {
        margin-right: 16px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.btn-recent-close {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: rgba(96, 93, 108, 0.55);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    right: 4px;

    svg {
        width: 8px;
    }
}

.processing-text-container {
    position: relative;
    text-align: center;
    border-radius: 12px;
    background-color: rgba(96, 93, 108, .4);
    width: calc(100% - 48px);
    margin: 0 auto 32px;
    padding: 10px;

    @media all and (max-width: 991px) {
        margin-bottom: 24px;
    }

    p {
        color: #a5a3ad;
        //white-space: nowrap;
        //overflow: hidden;
        //text-overflow: ellipsis;
    }

    svg {
        width: 30px;
        position: absolute;
        top: -13px;
        right: 18px;
    
    }
}

.loader-view-container {
    margin-bottom: 9vh;
}

.loader-view {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  
  .item-loader {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        transform: scale(1);
        margin: 0 6px;
    }
  
    .first-item-loader {
        // background-color: #64ad7c;
        animation: item-loader 1.2s ease-in-out infinite;
    }
  
    .second-item-loader {
        // background-color: #ffd300;
        animation: item-loader 1.2s ease-in-out infinite;
        animation-delay: .3s;
    }
  
    .third-item-loader {
        // background-color: #b7ffcf;
        animation: item-loader 1.2s ease-in-out infinite;
        animation-delay: .6s;
    }
}
  
@keyframes item-loader {
    0% {
        transform: scale(1);
        background-color: #64ad7c;
    }
    50% {
        transform: scale(1.8);
        background-color: #b7ffcf;
    }
    100% {
        transform: scale(1);
        background-color: #64ad7c;
    }
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.creative-error-message {
    p {
        text-align: center;
        padding: 0 20px;
    }
}

.creative-view {
    background-color: #605d6c;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 42px 20px 50px;

    svg {
        max-height: 17vh;
        margin-bottom: 16px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        text-align: center;
        margin-bottom: 5px;
    }

    p {
        text-align: center;
        margin-bottom: 24px;
    }

    button {
        font-size: 14px;
        font-weight: 700;
        color: #302c40;
        max-width: 194px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 25px;
        text-transform: uppercase;
        border: 1px solid #b7ffcf;
        background-color: #b7ffcf;
        box-sizing: border-box;
        margin: 0 auto;

        svg {
            width: 16px;
            height: auto;
            margin: 0;
            margin-left: 10px;
            margin-bottom: 2px;
        }
    }
}

.lds-default {
    width: 21px;
    height: 21px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lds-default div {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgb(172, 170, 178);
    animation: lds-default .96s linear infinite;
}

.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 2px;
    left: 12px;
}

.lds-default div:nth-child(2) {
    animation-delay: 0.12s;
    top: 7px;
    left: 16px;
}

.lds-default div:nth-child(3) {
    animation-delay: 0.24s;
    top: 13px;
    left: 15px;
}

.lds-default div:nth-child(4) {
    animation-delay: 0.36s;
    top: 17px;
    left: 10px;
}

.lds-default div:nth-child(5) {
    animation-delay: 0.48s;
    top: 16px;
    left: 4px;
}

.lds-default div:nth-child(6) {
    animation-delay: .6s;
    top: 11px;
    left: 0px;
}

.lds-default div:nth-child(7) {
    animation-delay: .72s;
    top: 4px;
    left: 1px;
}

.lds-default div:nth-child(8) {
    animation-delay: .84s;
    top: 1px;
    left: 6px;
}

@keyframes lds-default {
    0%, 20%, 80%, 100% {
        transform: scale(1);
        opcity: .2;
    }
    50% {
        transform: scale(0.5);
        opcity: .2;
        background-color: rgba(251, 212, 74, .2);
    }
}

.error-container,
.creative-error-message-container,
.creative-error-message {
    height: 100%;
}

.creative-error-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #302c40;
    padding: 0 20px;
    box-sizing: border-box;

    p {
        line-height: 20px;
        text-align: center;
        margin-bottom: 24px;
    }

    button {
        font-size: 14px;
        font-weight: 700;
        color: #302c40;
        max-width: 270px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 25px;
        text-transform: uppercase;
        border: 1px solid #b7ffcf;
        background-color: #b7ffcf;
        box-sizing: border-box;

        svg {
            width: 16px;
            margin-left: 10px;
        }
    }
}

.creative-download {
    margin: 0 auto;
}

.mask-container {
    position: relative;
    z-index: 20;

    .tooltip {
        font-size: 12px;
        letter-spacing: 1.2px;
        color: #302c40;
        position: absolute;
        top: 18px;
        right: 66px;
        height: 30px;
        background-color: #b7ffcf;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .34);
        min-width: 124px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        padding: 0 8px;

        span {
            white-space: nowrap;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: -6px;
            bottom: 9px;
            border-width: 5px 0 5px 6px;
            border-color: transparent transparent transparent #b7ffcf;
            width: 0;
            height: 0;
            border-style: solid;
        }
    }
}

.btn-change-mask {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #b7ffcf;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .34);
    padding: 0;

    svg {
        width: 24px;
        margin-top: 4px;
    }

    &.--animate {
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background-color: #01e777;
            animation: animate 3s ease 0s 2 forwards;
            z-index: -1;
        }

        @keyframes animate {
            0% {
                transform: translate(-50%, -50%) scale(1);
                opacity: 0;
            }
            49% {
                transform: translate(-50%, -50%) scale(1);
                opacity: 0;
            }
            50% {
                transform: translate(-50%, -50%) scale(1);
                opacity: 1;
            }
            100% {
                transform: translate(-50%, -50%) scale(2);
                opacity: 0;
            }
        }
    }
}

.webview {
    .main-page h1 {
        margin-top: 24px;
    }
}

.app-btn-hidden {
    .header {
        padding-top: 60px;
        box-sizing: border-box;
    
        @media all and (max-width: 991px) {
            padding-top: 24px;
        }
    }
}

.app-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    &.open {
        display: flex;
    }

    button {
        font-family: "AlegreyaSans";
        font-size: 26px;
        color: #302c40;
        height: 84px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fd7e22;
        border-top: 1px solid #302c40;
        box-sizing: border-box;

        svg {
            height: 42px;
            margin-right: 20px;
        }

        &:last-child {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 170px;
                display: block;
                background-image: url("/assets/images/bg-app.png");
                background-size: contain;
                background-repeat: no-repeat;
                z-index: -1;
            }
        }
    }
}

.apps-container {
    position: relative;
    align-self: flex-end;
}

.btn-back {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    background: none;
    padding: 4px;
    flex-shrink: 0;
    margin-left: 14px;
    margin-right: 4px;
    margin-top: -5px;

    svg {
        width: 100%;
    }
}
.copy {
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    box-sizing: border-box;
    margin-left: 3px;
    position: relative;
    top: 0px;
    border-radius: 4px;
    background: rgba(183, 255, 207, .3);

    svg {
        width: 100%;
        height: 100%;
        fill: #b7ffcf;
    }
}

.js-hashtag.--copied .copy {
    opacity: .6;
}

@media all and (max-width: 1200px) {
    .header {
        .apps-container {
            margin-bottom: 20px;
        }
    }
    .header .btn-app {
        font-size: 28px;
        width: 240px;
        height: 78px;

        &:after {
            height: 142px;
        }
    }

    .app-links button {
        height: 78px;
    }

    .main-page h1 {
        font-size: 42px;
        margin-bottom: 16px;
        max-width: 680px;
    }

    .form .btns-container {
        margin-top: 16px;
    }

    .footer {
        margin-top: 16px;
    }

    .main-page .btn-upload-foto {
        font-size: 26px;
        width: 234px;
        height: 70px;
    }
}

@media all and (max-width: 991px) {
    .slide-toolptip p {
        font-size: 16px;
        padding: 4px;
    }

    .slide-toolptip {
        bottom: 10px;
        left: -26px;

        svg {
            width: 70px;
            bottom: -30px;
        }
    }

    .slick-dots {
        display: none;
    }
}

@media all and (max-width: 700px) {
    .header {
        .apps-container {
            margin-bottom: 30px;
        }
    }

    .header .container {
        justify-content: center;
    }

    .header .btn-app {
        font-size: 22px;
        width: 170px;
        height: 54px;
    }

    .app-links button {
        font-size: 18px;
        height: 54px;
    }

    .app-links button svg {
        height: 28px;
        margin-right: 8px;
    }

    .main-page h1 {
        font-size: 28px;
        max-width: 460px;
    }

    .apps-container {
        align-self: center;
    }
}

@media all and (max-height: 880px) and (orientation: landscape) {
    .header .btn-app {
        font-size: 28px;
        width: 240px;
        height: 78px;

        &:after {
            height: 142px;
        }
    }

    .app-links button {
        height: 78px;
    }

    .main-page h1 {
        font-size: 42px;
        margin-bottom: 24px;
        max-width: 680px;
    }

    .form .btns-container {
        margin-top: 16px;
    }

    .footer {
        margin-top: 16px;
    }

    .main-page .btn-upload-foto {
        font-size: 26px;
        width: 234px;
        height: 70px;
    }
}

@media all and (max-height: 720px) and (orientation: landscape) {
    .header {
        .apps-container {
            margin-bottom: 20px;
        }
    }

    .main-page h1 {
        font-size: 32px;
    }

    .main-page .btn-upload-foto {
        font-size: 20px;
        width: 220px;
        height: 54px;
    }

    .header .btn-app {
        font-size: 18px;
        width: 160px;
        height: 52px;
    }

    .app-links button {
        font-size: 18px;
        height: 52px;
    }

    .app-links button svg {
        height: 28px;
        margin-right: 8px;
    }

    .slide-toolptip p {
        font-size: 16px;
        padding: 4px;
    }

    .slide-toolptip {
        bottom: 10px;
        left: -26px;

        svg {
            width: 70px;
        }
    }

    .slider .slick-slide > div {
        padding: 10px 0 30px;
    }
}

@media all and (max-height: 670px) and (orientation: portrait) {
    .main-page h1 {
        font-size: 24px;
    }

    .main-page .btn-upload-foto {
        font-size: 18px;
        height: 48px;
    }

    .form {
        margin-bottom: 20px;
    }

    .slider {
        .swiper-slide {
            width: 60%;
            padding-top: 60%;
        }
    }
}

@media all and (max-height: 660px) and (orientation: portrait) {
    .modal-container {
        padding: 16px;
        margin-top: 20px;
    }

    .modal-image,
    .modal p {
        margin-bottom: 16px;
    }

    .modal-image {
        max-height: 16vh;
    }

    .recent-image {
        width: 54px;
        height: 54px;
    }

    // .creative-block {
    //     max-height: 50vh;
    // }

    .processing-text-container {
        margin-bottom: 16px;
    }
}

@media all and (max-height: 642px) and (orientation: portrait) {
    .header {
        .apps-container {
            margin-bottom: 20px;
        }

        .btn-app {
            font-size: 18px;
            width: 160px;
            height: 52px;
        }
    }

    .app-links button {
        font-size: 18px;
        height: 52px;
    }

    .form {
        margin-bottom: 0;
    }

    .main-page .btn-upload-foto {
        font-size: 20px;
        width: 220px;
        height: 54px;
    }
}

@media all and (max-height: 550px) and (orientation: portrait) {
    .main-page h1 {
        font-size: 18px;
    }

    .header .btn-app {
        width: 130px;
        height: 42px;
    }

    .app-links button {
        font-size: 16px;
        height: 42px;

        svg {
            margin-right: 2px;
        }
    }

    .header {
        .apps-container {
            margin-bottom: 16px;
        }
    }

    .slider .slick-slide > div {
        padding: 10px 0 30px;
    }

    .main-page .btn-upload-foto {
        font-size: 18px;
        width: 200px;
        height: 40px;
    }
}
